/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";
@import "theme/fonts";
@import "theme/place-autocomplete-global";
@import "theme/month-selector-popover";
@import "theme/checkin-selects";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
body {
    font-size: 14px;
    &.cameraPreview {
        background-image: url(/assets/components/camera-preview/frame-id.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent;
        // background-color: red;
    }
}

:root {
    --ion-background-color: var(--ion-color-light);
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}

ion-menu ion-content {
    --background: var(--ion-color-secondary);
}

ion-toolbar {
    --background: transparent;
    --ion-color-base: transparent !important;
}

ion-icon {
    width: 24px;
    height: 24px;
}

.modal-booking {
    background-color: transparent;
    --background: transparent;
    .modal-wrapper {
        background: transparent;
        @media (min-width: 992px) {
            height: 100vh;
            width: 30vw;
        }
    }

    @media (min-width: 992px) {
        left: calc(100vw - 30vw);
    }
}

@media (min-width: 992px) {
    .modal-booking-list,
    .modal-password {
        height: 100vh;
        width: calc(100vw - 28vw);
        left: 28vw;
        .modal-wrapper, .modal-wrapper.sc-ion-modal-ios {
            width: 100%;
        }
    }
}


@media (min-width: 992px) {
    ion-modal .modal-wrapper,
    .modal-wrapper.sc-ion-modal-ios {
        height: 100vh;
        width: 30vw;
    }
}

.btn-auth-apple,
.btn-auth-gmail,
.btn-auth-facebook,
.btn-auth-linkedin,
.btn-auth-email {
    border: 1px solid #E1E9ED;
    margin: 8px auto;
    border-radius: 8px;
    --background-activated: black;
    --color-activated: white;
    span {
        display: flex;
        align-items: center;
        justify-items: left;
        font-weight: 600;
        width: 100%;
        img {
            margin-right: 8px;
        }
    }
}

.btn-auth-apple {
    --background: #F9FAFB;
    --color: #26587B;
}

.btn-auth-gmail {
    --background: #4285F4;
    --color: white;
}

.btn-auth-linkedin {
    --background: #006699;
    --color: white;
}

.btn-auth-email {
    --background: #F9FAFB;
    --color: #26587B;
}

.btn-link {
    --background: transparent;
    --background-activated: transparent;
    --color: #113958;
    --color-activated: var(--ion-color-success);
    text-decoration: underline;
}

ion-popover.popover-fakemodal {
    --width: 320px;
}

// hide arrow tooltip on fake modals
.popover-arrow,
.popover-arrow.sc-ion-popover-ios {
    visibility: hidden;
}

ion-item {
    --background: transparent;
    --background-focused: transparent;
    margin-bottom: 6px;
    .btn-link {
        color: #8EB1C8;
    }
    &.invalid {
        ion-input,
        ion-datetime {
            background: #F7F0EF;
            border: 1px solid #EBCDC7
        }
    }
    ion-label {
        color: #26587B;
        margin-top: 10px;
        font-weight: 600;
    }
}

form ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
}

ion-label {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
}

@media (min-width: 992px) {
    ion-backdrop {
        background-color: transparent;
    }
}

ion-input,
ion-datetime {
    --padding-start: 16px;
    --padding-end: 16px;
    --background: #F9FAFB;
    --placeholder-color: #C7D6E0;
    border: 1px solid #E1E9ED;
    border-radius: 8px;
    &.btn-submit {
        --color: white!important;
        color: white;
    }
}

ion-select {
    --padding-start: 16px;
    --padding-end: 16px;
    background: #F9FAFB;
    border: 1px solid #E1E9ED;
    border-radius: 8px;
}

ion-datetime {
    background-color: #F9FAFB;
    width: auto;
}

ion-item.ion-item-checkbox {
    --background-focused: transparent;
    --background-hover: transparent;
    --background-activated: transparent;
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.011em;
    ion-checkbox {
        --border-radius: 5px;
        margin-right: 10px;
    }
}

ion-content {
    .page-wrapper-with-footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}

